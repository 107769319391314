/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.search_container {
    padding-top: 4rem;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 3.25rem;
    height: calc(100vh - 7.25rem);
}

.search_nav {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    height: 3rem;
    background-color: var(--swatch-04);
    z-index: 2;

    width: 100vw;
}

.search_nav-back {
    margin-left: 1rem;
}

.search_input-container {
    flex-grow: 1;
    margin: 0 1rem; /* Adjust as needed */
}

.search_input-container input {
    
    width: 100%; /* Add this line */
    height: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
    border-radius: 0.75rem; /* Add this line */

    outline: none; /* Add this line */
    border: none; /* Add this line */
}

.no-events-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; /* Adjust based on your layout */
    text-align: center;
}

.user-info-account-table {
    width: 100%; /* Full width of the container */
    border-collapse: collapse; /* Ensures borders between cells are merged */
    background-color: var(--swatch-10); /* Light grey background for the table */
  }
  
.user-info-account-row {
    border-bottom: 1px solid var(--swatch-04); /* Light grey border for each row */
}

.user-info-account-row:last-child {
    border-bottom: none; /* Remove border for the last row */
}

.user-info-account-table td {
    padding: 0.25rem; /* Padding inside each cell */
    text-align: left; /* Align text to the left */
    vertical-align: middle; /* Align cell content vertically in the middle */
}

.user-info_row_data_label {
    background-color: var(--swatch-12);
    font-weight: var(--weight-03);
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(31, 38, 135, 0.37);
    padding: 0.0625rem 0.5rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    width: fit-content;
}

.user-info_row_data_value {
    padding: 0.0625rem 0.5rem;
}

.user-info-account-row-first {
    width: 3rem;
    background-color: var(--swatch-12);
    text-align: center !important;
    font-size: 2rem;
    cursor: pointer;
}

.user-info-account-row-first:hover {
    background-color: var(--swatch-10);
}