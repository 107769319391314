/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.toggle-net-container {
  display: flex;
  justify-content: flex-end;
}

.toggle-net {
  display: flex; /* Change display to inline-flex */
  align-items: center; /* Align items vertically */
}

.toggle-switch {
  display: flex;
  cursor: pointer;
}

.toggle-icon {
  margin-right: 0.5rem;
}

.custom-switch .MuiSwitch-thumb {
  background-color: var(--swatch-10);
  border: 0.125rem solid var(--swatch-05);
  box-sizing: border-box;
  background: -moz-linear-gradient(to top right, var(--swatch-08) 10%, var(--swatch-12) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(to top right, var(--swatch-08) 10%, var(--swatch-12) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top right, var(--swatch-08) 10%, var(--swatch-12) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.custom-switch .MuiSwitch-track {
  background-color: var(--swatch-05) !important;
  opacity: 1 !important;
  background: -moz-linear-gradient(to top right, var(--swatch-05) 10%, var(--swatch-08) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(to top right, var(--swatch-05) 10%, var(--swatch-08) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top right, var(--swatch-05) 10%, var(--swatch-08) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
