/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
#update-user-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0rem 1rem;

  height: calc(100vh - 3.5rem);
}

#update-user-profile > .input-container {
  flex-grow: inherit;
}

.picture-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  padding: 0.5rem;
  max-width: 30rem;
  flex-grow: 1;
  
  margin: 1rem auto;

  border: 0.0625rem outset var(--swatch-08);
  border-radius: 0.5rem;

  overflow-y: auto;

  box-shadow: 0 0.5rem 2rem 0 rgba( 31, 38, 135, 0.37 );
}

.profile_image {
  width: 4.0rem;
  height: 4.0rem;
  border-radius: 3rem;
  object-fit: cover;

  padding: 0.75rem;
  margin: 0.25rem;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.profile_image:hover {
  background: var(--swatch-12);
}

.profile_image.selected {
  background: var(--swatch-06);
  box-shadow: 0 0.5rem 2rem 0 rgba( 31, 38, 135, 0.37 );
}

.profile_image > img {
  width: 100%;
}