/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.user-profile-margin {
    max-width: 33rem;
    margin: 0 auto;
}

.user-profile-resources_button_container > .button {
    padding-left: 2rem;
    padding-right: 2rem;
}

.user-profile-resources_button_container svg {
    margin-right: 0.5rem;
}

.user_profile_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user_profile_heading > h4 {
    margin: 0;
    margin-left: 1rem;
}

.user_profile_image_container {
    display: flex;
    justify-content: center;
}

.user_profile_image_container > .profile_image  {
    width: 12rem;
    height: 12rem;
    
    cursor: default;
}

.user_profile_image_container > .profile_image:hover  {
    background: none;
}

.user-profile .resources.c2a {
    background: -moz-linear-gradient(to bottom right, var(--swatch-09) 0%, var(--swatch-12) 80%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to bottom right, var(--swatch-09) 0%, var(--swatch-12) 80%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom right, var(--swatch-09) 0%, var(--swatch-12) 80%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.org-mini-list-container {
    display: flex;
    justify-content: flex-start;
    overflow-x: auto; /* Enables horizontal scrolling */
    overflow-y: hidden;

    height: auto; /* Set a fixed height if necessary */
}

.org-mini-list,
.org-mini-list {
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.org-mini-list-centered {
    justify-content: space-evenly;
}

.org-mini-list > .card {
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 12rem;
    height: 10rem;
    padding-top: 0.25rem;
    display: flex;

    box-shadow: none;
}

.org-mini-list > .card > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

.org-mini-list > .card > div > h4,
.org-mini-list > .card > div > h6 {
    margin: 0 1rem;
}

/*
 * IDEA: It would be sensible to create a more common CSS file 
 * for each microserivce to share, at least to copy and paste
 * instead of bits, a whole file would be better
 */

.org-page_details > .card {
    padding: 1rem;
}

.org-page_details > .card {
    margin-bottom: 3rem;
}

.org-page_details {
    padding: 1rem;
}

.event_org_info_item {
    display: flex;
    justify-content: flex-start;
}
  
.event_org_info_item_wrapper {
    width: 7rem
}
  
.event_org_info_value {
    flex-grow: 1;
    max-width: 70%;
}
  
.event_org_info_label {
    background-color: var(--swatch-12);
    font-weight: var(--weight-03);
    box-shadow: 0 0.0625rem 0.25rem 0 rgba( 31, 38, 135, 0.37 );
    padding: 0.0625rem 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
}

.org-title {
    text-align: center !important;
    margin-left: 0;
}

.org-list_subscription_recent_costs {
    background: var(--swatch-12);
    box-shadow: 0 0.5rem 2rem 0 rgba( 31, 38, 135, 0.37 );
    border-radius: 0.75rem;
    margin: 1rem;
    margin-top: 0rem;
    padding: 1.5rem 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.org-list_subscription_recent_costs-full_details_button {
    margin: 0.25rem;
    padding: 0.5rem 1rem;
    margin-left: 1rem;

    color: var(--swatch-06);
    width: fit-content;

    background: var(--swatch-12);
    border-radius: 0.75rem;

    transform: translate(0%, 1.5rem);
}

.org-list_subscription_recent_costs-full_details_button:hover {
    color: var(--swatch-08);
    cursor: pointer;
}

.org-list_subscription_recent_costs-full_details_button > svg {
    margin-right: 0.25rem;
}

.org-list_subscription_recent_costs-amount {
    background: -moz-linear-gradient(to bottom right, var(--swatch-04) 0%, var(--swatch-07) 80%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to bottom right, var(--swatch-04) 0%, var(--swatch-07) 80%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom right, var(--swatch-04) 0%, var(--swatch-07) 80%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  
    font-weight: var(--weight-03);
    border-radius: 2rem;
    padding: 0.75rem;

    color: var(--swatch-10);
    min-width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.org-list_subscription_recent_costs-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.org-list_subscription_recent_costs-details > p {
    padding-bottom: 1rem;
    max-width: 20rem;
    margin-left: 0;
    margin-right: 0;
}

.org-list_subscription_recent_costs-details > p.c2a {
    border: 0.125rem solid var(--swatch-04);
    background: -moz-linear-gradient(to bottom right, var(--swatch-09) 0%, var(--swatch-12) 80%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to bottom right, var(--swatch-09) 0%, var(--swatch-12) 80%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom right, var(--swatch-09) 0%, var(--swatch-12) 80%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.card {
    background: var(--swatch-10);
    border-radius: 1rem;
  
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 0.5rem 2rem 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 0.25rem );
    -webkit-backdrop-filter: blur( 0.25rem );
    border-radius: 0.75rem;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    margin: 1rem;
  
    max-width: 32rem;
}
  
.card .button > svg{
    margin-right: 0.25rem;
}

#update-user-profile > div > .input-container {
    max-width: 33rem;
    margin: 0 auto;
}

.nav > .fa-button {
    cursor: pointer;
}